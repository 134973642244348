import * as actionTypes from "./action-types";

export function forgotPasswordStart() {
  return {
    type: actionTypes.FORGOT_PASSWORD_START,
  };
}

export function resetChangePassword() {
  return {
    type: actionTypes.RESET_CHANGE_PASSWORD,
  };
}

export function forgotPasswordFailed(error) {
  return {
    type: actionTypes.FIND_ACCOUNT_FAILED,
    message: error,
  };
}

export function forgotPasswordSuccess(changePasswordId, isUserRegistered) {
  return {
    type: actionTypes.FIND_ACCOUNT_SUCCESS,
    changePasswordId,
    isUserRegistered,
  };
}

export function forgotPassword(loginId) {
  return function (dispatch) {
    dispatch(forgotPasswordStart());
    fetch(
      "https://docket.palletman.com.au/development/public/forgot-password",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          loginId: loginId,
          sendForgotPasswordEmail: "FALSE",
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.status > 200) {
          let message = "Something went wrong!";

          if (data.status === 404) {
            message = "Incorrect username";
          }
          dispatch(forgotPasswordFailed(message));
        } else if (data.status === 200) {
          dispatch(
            forgotPasswordSuccess(data.successResponse.changePasswordId, true)
          );
        }
      })
      .catch((err) => {
        dispatch(forgotPasswordFailed(err.message));
      });
  };
}

export function changePasswordFailed(error) {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAILED,
    message: error,
  };
}

export function changePasswordSuccess(isSuccessful) {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    isSuccessful,
  };
}

export function changePassword(password, changePasswordId) {
  return function (dispatch) {
    dispatch(forgotPasswordStart());
    fetch(
      "https://docket.palletman.com.au/development/public/change-user-password",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          newpassword: password,
          changepasswordid: changePasswordId,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.status > "UNSUCCESSFUL") {
          let message =
            "Grace time has elapse. Please start the process again.";

          dispatch(changePasswordFailed(message));
        } else {
          dispatch(changePasswordSuccess(true));
        }
      })
      .catch((err) => {
        dispatch(changePasswordFailed(err.message));
      });
  };
}
