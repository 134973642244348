import { useEffect } from "react";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionCreators from "../../store/actions";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "../ui/form/useForm";
import Controls from "../ui/form/controls/controls";
import FirstTimeLogin from "./first-time-login";

import Spinner from "../ui/spinner";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    padding: 20,
    height: "70vh",
    width: 320,
    margin: "20px auto",
  },
  avatarStyle: {
    backgroundColor: "green",
  },
  btnStyle: {
    margin: "8px 0",
  },
  error: {
    color: theme.palette.secondary.main,
  },
  spinner: {
    textAlign: "center",
  },
}));

function Login(props) {
  const classes = useStyles();
  const { authToken, error, errorMessage, onLogin, loading, changePasswordId } =
    props;
  const history = useHistory();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("username" in fieldValues) {
      temp.username = fieldValues.username ? "" : "This field is required";
    }

    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length > 7 ? "" : "Mininum character is 8";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((field) => field === "");
    }
  };
  const { values, setValues, inputChangeHandler, errors, setErrors } = useForm(
    { username: "", password: "" },
    true,
    validate
  );

  useEffect(() => {
    if (changePasswordId) {
      setValues({ username: "", password: "" });
    }
  }, [changePasswordId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onLogin(values.username, values.password);
    }
  };

  const forgotPassword = (event) => {
    event.preventDefault();
    history.push("/forgot-password");
  };

  if (authToken) {
    history.replace("/");
  }

  if (changePasswordId) {
    return <FirstTimeLogin changePasswordId={changePasswordId} />;
  }

  return (
    <Grid container>
      <Paper elevation={10} className={classes.paperStyle}>
        <>
          <Grid align="center">
            <Avatar className={classes.avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>Sign In</h2>
          </Grid>

          <Controls.Input
            name="username"
            label="Username"
            value={values.username}
            onChange={inputChangeHandler}
            error={errors.username}
            fullWidth
            required
            placeholder="Enter username"
            className={classes.btnStyle}
          />
          <Controls.Input
            name="password"
            label="Password"
            value={values.password}
            onChange={inputChangeHandler}
            error={errors.password}
            placeholder="Enter password"
            type="password"
            fullWidth
            required
            className={classes.btnStyle}
          />
          {errorMessage && (
            <span className={classes.error}>{errorMessage}</span>
          )}

          <FormControlLabel
            control={<Checkbox name="checkedB" color="primary" />}
            label="Remember me"
          />
          {!loading ? (
            <Controls.Button
              onClick={handleSubmit}
              className={classes.btnStyle}
              fullWidth
              text="Sign In"
            />
          ) : (
            <Spinner color="primary" />
          )}
        </>
        <Typography>
          <Link href="#" onClick={forgotPassword}>
            Forgot Password?
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.authToken,
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    loading: state.auth.loading,
    changePasswordId: state.auth.changePasswordId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userId, password) =>
      dispatch(actionCreators.authenticate(userId, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
