import Docket from "../models/docket";

const KEYS = {
  dockets: "dockets",
  docketId: "docketId",
};

export function transformDocket(docket, suburbArray, edit, driverId) {
  const suburbId = suburbArray.find((item) => item.title === docket.suburb);

  return {
    docketrunsid: docket.id,
    runsenterdate: docket.enteredDate,
    runscompleteddate: docket.completedDate,
    edit: edit,
    iscompleted: docket.completed === "no" ? 0 : 1,
    isreturned: docket.returned === "no" ? 0 : 1,
    deldocket: docket.deliveryDocketNumber,
    salesorder: docket.salesOrderNumber,
    suburb: docket.suburb,
    docketratetypename: docket.rateType,
    loadno: docket.loadNumber,
    dropno: docket.dropNumber,
    pallets: docket.palletQuantity,
    tonnes: docket.tonnes,
    kilometre: docket.kilometre,
    clientname: docket.client,
    rego: docket.rego,
    suburbid: suburbId.id,
    docketratetypeid: docket.rateType === "REGULAR" ? 1 : 2,
    completedby: 1,
    returncomment: "",
    returnimage: "",
    driverid: driverId,
    returncomment: "",
    returnimage: "",
  };
}

export function initializeDocketForm() {
  return new Docket(
    0,
    "1",
    "1",
    "1",
    "",
    "",
    new Date(),
    new Date(),
    "",
    "",
    "",
    "",
    "100",
    "REGULAR",
    "no",
    "no"
  );
}

export function simpleRadioGroupItems() {
  return [
    { id: "no", title: "No" },
    { id: "yes", title: "Yes" },
  ];
}

export function getRateType() {
  return [
    { id: "REGULAR", title: "REGULAR" },
    { id: "NIGHT", title: "NIGHT" },
  ];
}

export function insertDocket(data) {
  let dockets = getAllDockets();
  data["id"] = generateDocketId();
  dockets.push(data);
  localStorage.setItem(KEYS.dockets, JSON.stringify(dockets));
}

export function generateDocketId() {
  if (localStorage.getItem(KEYS.docketId) === null) {
    localStorage.setItem(KEYS.docketId, "0");
  }
  let id = parseInt(localStorage.getItem(KEYS.docketId));
  localStorage.setItem(KEYS.docketId, (++id).toString());
  return id;
}

export function getAllDockets() {
  if (localStorage.getItem(KEYS.dockets) === null) {
    localStorage.setItem(KEYS.dockets, JSON.stringify([]));
  }

  return JSON.parse(localStorage.getItem(KEYS.dockets));

  /*let rateTypes = getRateType();

  dockets.map((item) => ({
      ...item,

  }))*/
}

export function updateDocket(data) {
  let dockets = getAllDockets();
  let recordIndex = dockets.findIndex((x) => x.id === data.id);
  dockets[recordIndex] = { ...data };
  localStorage.setItem(KEYS.dockets, JSON.stringify(dockets));
}

export function deleteDocket(id) {
  let dockets = getAllDockets();
  dockets = dockets.filter((x) => x.id !== id);
  localStorage.setItem(KEYS.dockets, JSON.stringify(dockets));
}
