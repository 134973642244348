import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionCreators from "../../store/actions";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "../ui/form/useForm";
import Controls from "../ui/form/controls/controls";

import Spinner from "../ui/spinner";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    padding: 20,
    height: "70vh",
    width: 320,
    margin: "20px auto",
  },
  avatarStyle: {
    backgroundColor: "green",
    marginBottom: theme.spacing(3),
  },
  btnStyle: {
    margin: "8px 0",
  },
  error: {
    color: theme.palette.secondary.main,
  },
  spinner: {
    textAlign: "center",
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  const {
    onForgotPassword,
    onChangePassword,
    error,
    loading,
    changePasswordId,
    isUserRegistered,
    isSuccessful,
  } = props;
  const history = useHistory();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("username" in fieldValues) {
      temp.username = fieldValues.username ? "" : "This field is required";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((field) => field === "");
    }
  };
  const { values, setValues, inputChangeHandler, errors, setErrors } = useForm(
    { username: "" },
    true,
    validate
  );

  const forgotPasswordHandler = (event) => {
    event.preventDefault();
    if (validate()) {
      onForgotPassword(values.username);
    }
  };

  if (isUserRegistered && changePasswordId) {
    history.push("/change-password");
  }

  return (
    <Grid container>
      <Paper elevation={10} className={classes.paperStyle}>
        <>
          <Grid align="center">
            <Avatar className={classes.avatarStyle}>
              <AccountBoxOutlinedIcon />
            </Avatar>
            <Typography variant="subtitle2">
              Enter your Username or Email to find your account
            </Typography>
          </Grid>

          <Controls.Input
            name="username"
            label="Username"
            value={values.username}
            onChange={inputChangeHandler}
            error={errors.username}
            fullWidth
            required
            placeholder="Enter username or email"
            className={classes.btnStyle}
          />

          {error && <span className={classes.error}>{error}</span>}

          {!loading ? (
            <Controls.Button
              onClick={forgotPasswordHandler}
              className={classes.btnStyle}
              fullWidth
              text="Submit"
            />
          ) : (
            <Spinner color="primary" />
          )}
        </>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
    changePasswordId: state.forgotPassword.changePasswordId,
    isUserRegistered: state.forgotPassword.isUserRegistered,
    isSuccessful: state.forgotPassword.isSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPassword: (loginId) =>
      dispatch(actionCreators.forgotPassword(loginId)),
    onChangePassword: (password, changeId) =>
      dispatch(actionCreators.changePassword(password, changeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
