import Input from "./input";
import RadioGroup from "./radio-group";
import Select from "./select";
import DatePicker from "./date-picker";
import Button from "./button";
import ActionButton from "./action-button";
import AutoComplete from "./auto-complete";

const Controls = {
  Input,
  RadioGroup,
  Select,
  DatePicker,
  Button,
  ActionButton,
  AutoComplete,
};

export default Controls;
