import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Divider, ThemeProvider } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Copyright from "./components/layout/copyright";
import Login from "./components/auth/login";
import ForgotPassword from "./components/auth/forgot-password";
import ChangePassword from "./components/auth/change-password";
import Header from "./components/layout/header";
import DocketEntry from "./components/docket/docket-entry";
import theme from "./components/layout/theme";

const useStyles = makeStyles({
  appMain: {
    /*paddingLeft: "320px",*/
    width: "100%",
  },
});

function App(props) {
  const classes = useStyles();
  const { onTryAutoLogin, isAuthenticated, onResetDocketList } = props;

  let routes = (
    <Switch>
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/auth" exact component={Login} />
      <Redirect to="/auth" push={false} />
    </Switch>
  );

  if (isAuthenticated) {
    routes = (
      <div className={classes.appMain}>
        <Header />
        <Route path="/" exact component={DocketEntry} />
        <Redirect to="/" push={false} />
      </div>
    );
  }

  useEffect(() => {
    if (!isAuthenticated) {
      onResetDocketList();
    }
    onTryAutoLogin();
  }, [onTryAutoLogin, isAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      {routes}

      <Copyright />
      <CssBaseline />
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authToken !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoLogin: () => dispatch(actionCreators.authCheckState()),
    onResetDocketList: () => dispatch(actionCreators.resetDocketList()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
