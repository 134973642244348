import Docket from "../models/docket";

export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const getDocketObject = (jsonData, docketList) => {
  const docketIndex = docketList.findIndex(
    (docket) => docket.id === jsonData.docketrunsid
  );

  const completed = parseInt(jsonData.iscompleted) === 0 ? "no" : "yes";
  const returned = parseInt(jsonData.isreturned) === 0 ? "no" : "yes";

  const updatedDocket = new Docket(
    jsonData.docketrunsid,
    jsonData.loadno,
    jsonData.dropno,
    jsonData.rego,
    jsonData.suburb,
    jsonData.clientname,
    jsonData.runsenterdate,
    jsonData.runscompleteddate,
    jsonData.deldocket,
    jsonData.salesorder,
    jsonData.pallets,
    jsonData.tonnes,
    jsonData.kilometre,
    jsonData.docketratetypename,
    completed,
    returned
  );

  return {
    docket: updatedDocket,
    docketIndex: docketIndex,
  };
};
