import * as actionTypes from "./action-types";

export function addOrEditDocketStart() {
  return {
    type: actionTypes.ADD_OR_EDIT_DOCKET_START,
  };
}

export function inValidateReferenceNumber() {
  return {
    type: actionTypes.INVALIDATE_REF_NUM,
  };
}

export function addDocketSuccess(referenceNumber) {
  return {
    type: actionTypes.ADD_DOCKET_SUCCESS,
    referenceNumber: referenceNumber,
  };
}

export function editDocketSuccess(jsonData) {
  return {
    type: actionTypes.EDIT_DOCKET_SUCCESS,
    jsonData: jsonData,
  };
}

export function addOrEditDocketFailed(error) {
  return {
    type: actionTypes.ADD_OR_EDIT_DOCKET_FAILED,
    message: error,
  };
}

export function addOrEditDocket(jsonData, operationType) {
  return function (dispatch) {
    dispatch(addOrEditDocketStart());
    fetch(
      "https://docket.palletman.com.au/development/public/save-or-edit-docket",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          ...jsonData,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (operationType === 0) {
          dispatch(addDocketSuccess(data.docketrunsid));
        } else {
          dispatch(editDocketSuccess(jsonData));
        }
      })
      .catch((err) => {
        dispatch(addOrEditDocketFailed(err.message));
      });
  };
}
