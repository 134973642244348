import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function AutoCompleteInput(props) {
  const {
    id,
    name,
    label,
    data,
    value,
    error = null,
    onChange,
    ...other
  } = props;
  return (
    <Autocomplete
      freeSolo
      id={id}
      disableClearable
      options={data.map((option) => option.title)}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant="outlined"
          {...(error && { error: true, helperText: error })}
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
}

export default AutoCompleteInput;
