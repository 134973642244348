import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  initializeDocketForm,
  simpleRadioGroupItems,
  getRateType,
} from "../../../lib/utility";
import Controls from "./controls/controls";
import { useForm, Form } from "./useForm";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
}));

const RADIOGROUPITEMS = simpleRadioGroupItems();
const RATETYPEITEMS = getRateType();

function DocketEntryForm(props) {
  const { addOrEdit, recordForEdit, setOpenModal, suburbList, clientList } =
    props;
  const classes = useStyles();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("loadNumber" in fieldValues) {
      temp.loadNumber = fieldValues.loadNumber ? "" : "This field is required";
    }
    if ("dropNumber" in fieldValues) {
      temp.dropNumber = fieldValues.dropNumber ? "" : "This field is required";
    }
    if ("suburb" in fieldValues) {
      temp.suburb = fieldValues.suburb ? "" : "This field is required";
    }
    if ("client" in fieldValues) {
      temp.client = fieldValues.client ? "" : "This field is required";
    }
    if ("deliveryDocketNumber" in fieldValues) {
      temp.deliveryDocketNumber = fieldValues.deliveryDocketNumber
        ? ""
        : "This field is required";
    }
    if ("salesOrderNumber" in fieldValues) {
      temp.salesOrderNumber = fieldValues.salesOrderNumber
        ? ""
        : "This field is required";
    }
    if ("rego" in fieldValues) {
      temp.rego = fieldValues.rego ? "" : "This field is required";
    }
    if ("tonnes" in fieldValues) {
      temp.tonnes = fieldValues.tonnes ? "" : "This field is required";
    }
    if ("palletQuantity" in fieldValues) {
      temp.palletQuantity = fieldValues.palletQuantity
        ? ""
        : "This field is required";
    }
    if ("kilometre" in fieldValues) {
      temp.kilometre = fieldValues.kilometre ? "" : "This field is required";
    }
    if ("rateType" in fieldValues) {
      temp.rateType = fieldValues.rateType ? "" : "This field is required";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((field) => field === "");
    }
  };

  const {
    values,
    setValues,
    inputChangeHandler,
    errors,
    setErrors,
    cancelForm,
    inputChangeAutoCompleteHandler,
  } = useForm(initializeDocketForm(), true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      addOrEdit(values, cancelForm);
    }
  };

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Controls.Input
            name="loadNumber"
            label="Load Number"
            value={values.loadNumber}
            onChange={inputChangeHandler}
            error={errors.loadNumber}
          />

          <Controls.Input
            name="dropNumber"
            label="Drop Number"
            value={values.dropNumber}
            onChange={inputChangeHandler}
            error={errors.dropNumber}
          />

          <Controls.Input
            name="rego"
            label="Rego No"
            value={values.rego}
            onChange={inputChangeHandler}
            error={errors.rego}
          />

          <Controls.Input
            name="kilometre"
            label="Kilometres"
            value={values.kilometre}
            onChange={inputChangeHandler}
            error={errors.kilometre}
          />

          <Controls.Input
            name="deliveryDocketNumber"
            label="Delivery Docket"
            value={values.deliveryDocketNumber}
            onChange={inputChangeHandler}
            error={errors.deliveryDocketNumber}
          />

          <Controls.Input
            name="salesOrderNumber"
            label="Sales Order"
            value={values.salesOrderNumber}
            onChange={inputChangeHandler}
            error={errors.salesOrderNumber}
          />

          <Controls.Input
            name="tonnes"
            label="Tonnes"
            value={values.tonnes}
            onChange={inputChangeHandler}
            error={errors.tonnes}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controls.AutoComplete
            id="suburb"
            name="suburb"
            label="Suburb"
            value={values.suburb}
            onChange={inputChangeAutoCompleteHandler}
            error={errors.suburb}
            data={suburbList}
          />

          <Controls.AutoComplete
            id="client"
            name="client"
            label="Client"
            value={values.client}
            onChange={inputChangeAutoCompleteHandler}
            error={errors.client}
            data={clientList}
          />

          <Controls.Input
            name="palletQuantity"
            label="Pallet Quantity"
            value={values.palletQuantity}
            onChange={inputChangeHandler}
            error={errors.palletQuantity}
          />

          <Controls.DatePicker
            name="enteredDate"
            label="Entered Date"
            value={values.enteredDate}
            onChange={inputChangeHandler}
          />
          <Controls.DatePicker
            name="completedDate"
            label="Completed Date"
            value={values.completedDate}
            onChange={inputChangeHandler}
          />
          <Controls.Select
            name="rateType"
            value={values.rateType}
            label="Rate Type"
            onChange={inputChangeHandler}
            options={RATETYPEITEMS}
            error={errors.rateType}
          />
          <div className={classes.radioGroup}>
            <Controls.RadioGroup
              name="completed"
              value={values.completed}
              onChange={inputChangeHandler}
              label="Mark as Completed"
              items={RADIOGROUPITEMS}
            />
            <Controls.RadioGroup
              name="returned"
              value={values.returned}
              onChange={inputChangeHandler}
              label="Mark as Returned"
              items={RADIOGROUPITEMS}
            />
          </div>

          <div>
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={() => {
                setOpenModal(false);
                cancelForm();
              }}
            />
            <Controls.Button text="Submit" type="submit" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    suburbList: state.autoData.suburbList,
    clientList: state.autoData.clientList,
  };
};

/*const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDocketRuns: (driverId, offset) =>
      dispatch(actionCreators.fetchDocketRuns(driverId, offset)),
    onFetchAllSuburb: () => dispatch(actionCreators.fetchAllSuburb()),
    onFetchAllClient: () => dispatch(actionCreators.fetchAllClient())
  };
};*/

export default connect(mapStateToProps)(DocketEntryForm);
