import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

export function useForm(initialValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const inputChangeAutoCompleteHandler = (event, value) => {
    const { id } = event.target;

    const name = id.toString().split("-")[0];

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const cancelForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    inputChangeHandler,
    errors,
    setErrors,
    cancelForm,
    inputChangeAutoCompleteHandler,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      {children}
    </form>
  );
}
