class Docket {
  constructor(
    id,
    loadNumber,
    dropNumber,
    rego,
    suburb,
    client,
    enteredDate,
    completedDate,
    deliveryDocketNumber,
    salesOrderNumber,
    palletQuantity,
    tonnes,
    kilometre,
    rateType,
    completed,
    returned
  ) {
    this.id = id;
    this.loadNumber = loadNumber;
    this.dropNumber = dropNumber;
    this.rego = rego;
    this.suburb = suburb;
    this.client = client;
    this.enteredDate = enteredDate;
    this.completedDate = completedDate;
    this.deliveryDocketNumber = deliveryDocketNumber;
    this.salesOrderNumber = salesOrderNumber;
    this.palletQuantity = palletQuantity;
    this.tonnes = tonnes;
    this.kilometre = kilometre;
    this.rateType = rateType;
    this.completed = completed;
    this.returned = returned;
  }
}

export default Docket;
