export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const IS_FIRST_TIME_LOGIN = "IS_FIRST_TIME_LOGIN";
export const RESET_IS_FIRST_TIME_LOGIN = "RESET_IS_FIRST_TIME_LOGIN";
export const CHANGE_PASSWORD_ON_FIRST_TIME_LOGIN_SUCCESS =
  "CHANGE_PASSWORD_ON_FIRST_TIME_LOGIN_SUCCESS";

export const FETCH_DOCKET_RUNS = "FETCH_DOCKET_RUNS";
export const FETCH_MORE_DOCKET_RUNS_SUCCESS = "FETCH_MORE_DOCKET_RUNS_SUCCESS";
export const FETCH_DOCKET_RUNS_START = "FETCH_DOCKET_RUNS_START";
export const FETCH_MORE_DOCKET_RUNS_START = "FETCH_MORE_DOCKET_RUNS_START";
export const FETCH_DOCKET_RUNS_FAILED = "FETCH_DOCKET_RUNS_FAILED";
export const FETCH_DOCKET_RUNS_SUCCESS = "FETCH_DOCKET_RUNS_SUCCESS";
export const UPDATE_DOCKET_LIST = "UPDATE_DOCKET_LIST";
export const RESET_DOCKET_LIST = "RESET_DOCKET_LIST";

export const FETCH_SUBURB_SUCCESS = "FETCH_SUBURB_SUCCESS";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";

export const ADD_OR_EDIT_DOCKET_START = "ADD_OR_EDIT_DOCKET_START";
export const ADD_DOCKET_SUCCESS = "ADD_DOCKET_SUCCESS";
export const EDIT_DOCKET_SUCCESS = "EDIT_DOCKET_SUCCESS";
export const ADD_OR_EDIT_DOCKET_FAILED = "ADD_OR_EDIT_DOCKET_FAILED";
export const INVALIDATE_REF_NUM = "INVALIDATE_REF_NUM";

export const DELETE_DOCKET_START = "DELETE_DOCKET_START";
export const DELETE_DOCKET_SUCCESS = "DELETE_DOCKET_SUCCESS";
export const DELETE_DOCKET_FAILED = "DELETE_DOCKET_FAILED";
export const RESET_DELETED_DOCKET_OPERATION = "RESET_DELETED_DOCKET_OPERATION";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FIND_ACCOUNT_SUCCESS = "FIND_ACCOUNT_SUCCESS";
export const FIND_ACCOUNT_FAILED = "FIND_ACCOUNT_FAILED";
export const RESET_ACCOUNT_FOUND = "RESET_ACCOUNT_FOUND";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";
