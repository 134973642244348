import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import PageHeader from "../layout/page-header";
import DocketEntryForm from "../ui/form/docket-entry-form";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";

import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import Spinner from "../ui/spinner";

import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import useTable from "../ui/table/useTable";
import { transformDocket } from "../../lib/utility";
import Controls from "../ui/form/controls/controls";
import { Search } from "@material-ui/icons";
import Modal from "../ui/modal/modal";
import Notification from "../ui/notification/notification";
import ConfirmDialog from "../ui/notification/confirm-dialog";
import ShowRefNoDialog from "../ui/notification/show-ref-dailog";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: "50px",
    margin: "10px",
    padding: "10px",
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
  },
  searchInput: {
    width: "50%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  root: {
    "& .MuiButtonBase-root": {
      margin: "0 5px 0 0",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  error: {
    color: theme.palette.secondary.main,
  },
}));

const headCells = [
  { id: "deliveryDocketNumber", label: "Delivery Docket" },
  { id: "suburb", label: "Suburb" },
  { id: "client", label: "Client", disableSorting: true },
  { id: "palletQuantity", label: "Quantity" },
  { id: "tonnes", label: "Tonnes" },
  { id: "completed", label: "Completed", disableSorting: true },
  { id: "actions", label: "Actions", disableSorting: true },
];

function DocketEntry(props) {
  const classes = useStyles();
  const {
    docketData,
    errorMessage,
    loading,
    onFetchDocketRuns,
    onFetchAllSuburb,
    onFetchAllClient,
    recordTotal,
    onFetchMoreDocketRuns,
    loadingMore,
    suburbArray,
    onAddOrEditDocket,
    onUpdateDocketSuccess,
    referenceNumber,
    spinning,
    errorAddingDocket,
    onInvalidateRefNum,
    driverId,
    onDeleteDocket,
    onResetDeleteOperation,
    isDeleting,
    isDeleted,
    deleteFailed,
  } = props;
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(1);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [showRefDialog, setShowRefDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const loadMoreData = (newPage) => {
    if (newPage === offset) {
      onFetchMoreDocketRuns(driverId, offset + 1);
      setOffset(offset + 1);
    }
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, total, loadMoreData, offset);

  useEffect(() => {
    onFetchDocketRuns(driverId, offset);
  }, [onFetchDocketRuns, isDeleted]);

  useEffect(() => {
    onFetchAllSuburb();
    onFetchAllClient();
  }, [onFetchAllSuburb, onFetchAllClient]);

  useEffect(() => {
    if (docketData.length > 0) {
      setRecords(docketData);
      setTotal(recordTotal);
    }
  }, [docketData, recordTotal]);

  useEffect(() => {
    if (referenceNumber) {
      setShowRefDialog({
        isOpen: true,
        title: referenceNumber,
        subTitle:
          "Please write down the above number for your reference of this job!",
        onConfirm: () => {
          onCloseDialog();
        },
      });
    }
  }, [referenceNumber]);

  useEffect(() => {
    if (isDeleted) {
      onResetDeleteOperation();
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "error",
      });
    }
  }, [isDeleted]);

  const searchHandler = (event) => {
    let target = event.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((item) =>
            item.deliveryDocketNumber.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };

  const addOrEdit = (docket, cancelForm) => {
    let message;
    let edit;
    if (docket.id === 0) {
      edit = 0;
      message = "Submitted Successfully";

      const jsonData = transformDocket(docket, suburbArray, edit, driverId);
      onAddOrEditDocket(jsonData, edit);
    } else {
      edit = 1;
      message = "Updated Successfully";

      const jsonData = transformDocket(docket, suburbArray, edit, driverId);
      onAddOrEditDocket(jsonData, edit);
      onUpdateDocketSuccess(jsonData);
    }
    cancelForm();
    setRecordForEdit(null);
    setOpenModal(false);

    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenModal(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    onDeleteDocket(id);
  };

  const onCloseDialog = () => {
    setShowRefDialog({
      ...showRefDialog,
      isOpen: false,
    });
    onInvalidateRefNum();
    onFetchDocketRuns(driverId, 1);
    setOffset(1);
  };

  return (
    <>
      <PageHeader
        title="New Docket"
        subTitle="Enter a new docket details"
        icon={<ReceiptOutlinedIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search Docket#"
            className={classes.searchInput}
            onChange={searchHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Controls.Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenModal(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>

        {!loading ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.deliveryDocketNumber}</TableCell>
                    <TableCell>{item.suburb}</TableCell>
                    <TableCell>{item.client}</TableCell>
                    <TableCell>{item.palletQuantity}</TableCell>
                    <TableCell>{item.tonnes}</TableCell>
                    <TableCell>{item.completed.toUpperCase()}</TableCell>
                    <TableCell className={classes.root}>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => openInPopup(item)}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            {loadingMore && <Spinner color="primary" />}
            {errorMessage && (
              <span className={classes.error}>{errorMessage}</span>
            )}
            {isDeleting && <Spinner color="primary" />}
            <TblPagination />
          </>
        ) : (
          <Spinner color="primary" />
        )}
      </Paper>
      <Modal
        title="Docket Form"
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <DocketEntryForm
          setOpenModal={setOpenModal}
          addOrEdit={addOrEdit}
          recordForEdit={recordForEdit}
        />
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ShowRefNoDialog showRefDialog={showRefDialog} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    docketData: state.docket.docketData,
    error: state.docket.error,
    errorMessage: state.docket.errorMessage,
    loading: state.docket.loading,
    recordTotal: state.docket.total,
    loadingMore: state.docket.loadingMore,
    suburbArray: state.autoData.suburbList,
    referenceNumber: state.addOrEdit.referenceNumber,
    spinning: state.addOrEdit.loading,
    errorAddingDocket: state.addOrEdit.errorMessage,
    driverId: state.auth.driverData,
    isDeleting: state.docket.isDeleting,
    isDeleted: state.docket.isDeleted,
    deleteFailed: state.docket.deleteErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDocketRuns: (driverId, offset) =>
      dispatch(actionCreators.fetchDocketRuns(driverId, offset)),
    onFetchAllSuburb: () => dispatch(actionCreators.fetchAllSuburb()),
    onFetchAllClient: () => dispatch(actionCreators.fetchAllClient()),
    onFetchMoreDocketRuns: (driverId, offset) =>
      dispatch(actionCreators.fetchMoreDocketRuns(driverId, offset)),
    onAddOrEditDocket: (jsonData, operationType) =>
      dispatch(actionCreators.addOrEditDocket(jsonData, operationType)),
    onUpdateDocketSuccess: (jsonData) =>
      dispatch(actionCreators.updateDocketList(jsonData)),
    onInvalidateRefNum: () =>
      dispatch(actionCreators.inValidateReferenceNumber()),
    onDeleteDocket: (docketId) =>
      dispatch(actionCreators.deleteDocket(docketId)),
    onResetDeleteOperation: () =>
      dispatch(actionCreators.resetDeletedDocketOperation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocketEntry);

/*
<Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure you want to delete this record?",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>

*/
