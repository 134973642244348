import * as actionTypes from "./action-types";

export function fetchAllSuburbSuccess(data) {
  return {
    type: actionTypes.FETCH_SUBURB_SUCCESS,
    data: data,
  };
}

export function fetchAllSuburb() {
  return function (dispatch) {
    fetch("https://docket.palletman.com.au/development/public/fetch-all-suburb")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.data.length > 0) {
          dispatch(fetchAllSuburbSuccess(data.data));
        }
      })
      .catch((err) => {});
  };
}

export function fetchAllClientSuccess(data) {
  return {
    type: actionTypes.FETCH_CLIENT_SUCCESS,
    data: data,
  };
}

export function fetchAllClient() {
  return function (dispatch) {
    fetch(
      "https://docket.palletman.com.au/development/public/load-client-names"
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.length > 0) {
          const clients = [];

          data.map((item) => {
            clients.push({
              title: item.name,
              id: item.value,
            });
          });
          dispatch(fetchAllClientSuccess(clients));
        }
      })
      .catch((err) => {});
  };
}
