import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
  loading: false,
  changePasswordId: null,
  isSuccessful: false,
  isUserRegistered: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_START:
      return updateObject(state, {
        loading: true,
      });
    case actionTypes.FIND_ACCOUNT_FAILED:
      return updateObject(state, {
        error: action.message,
        loading: false,
      });
    case actionTypes.FIND_ACCOUNT_SUCCESS:
      return updateObject(state, {
        error: null,
        loading: false,
        changePasswordId: action.changePasswordId,
        isUserRegistered: action.isUserRegistered,
      });
    case actionTypes.CHANGE_PASSWORD_FAILED:
      return updateObject(state, {
        error: action.message,
        loading: false,
      });
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return updateObject(state, {
        isSuccessful: action.isSuccessful,
        loading: false,
        error: null,
      });
    case actionTypes.RESET_CHANGE_PASSWORD:
      return updateObject(state, {
        ...initialState,
      });
    default:
      return state;
  }
};

export default reducer;
