import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
  referenceNumber: null,
  loading: false,
  error: null,
  errorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_OR_EDIT_DOCKET_START:
      return updateObject(state, {
        loading: true,
      });
    case actionTypes.ADD_DOCKET_SUCCESS:
      return updateObject(state, {
        referenceNumber: action.referenceNumber,
        loading: false,
        errorMessage: "",
        error: null,
      });
    case actionTypes.EDIT_DOCKET_SUCCESS:
      return updateObject(state, {
        referenceNumber: null,
        loading: false,
        errorMessage: "",
        error: null,
      });
    case actionTypes.ADD_OR_EDIT_DOCKET_FAILED:
      return updateObject(state, {
        referenceNumber: null,
        loading: false,
        errorMessage: action.message,
        error: true,
      });
    case actionTypes.INVALIDATE_REF_NUM:
      return updateObject(state, {
        referenceNumber: null,
      });
    default:
      return state;
  }
};

export default reducer;
