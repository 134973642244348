import { useState, useEffect } from "react";
import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "../ui/form/useForm";
import Controls from "../ui/form/controls/controls";

import Spinner from "../ui/spinner";
import ShowRefNoDialog from "../ui/notification/show-ref-dailog";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    padding: 20,
    height: "70vh",
    width: 320,
    margin: "20px auto",
  },
  avatarStyle: {
    backgroundColor: "green",
    marginBottom: theme.spacing(3),
  },
  btnStyle: {
    margin: "8px 0",
  },
  error: {
    color: theme.palette.secondary.main,
  },
  spinner: {
    textAlign: "center",
  },
}));

function FirstTimeLogin(props) {
  const classes = useStyles();
  const {
    onResetChangePassword,
    onChangePasswordOnFirstTime,
    error,
    errorMessage,
    loading,
    changePasswordId,
    passwordChangeSuccess,
  } = props;

  const [showRefDialog, setShowRefDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length > 7 ? "" : "Mininum character is 8";
    }
    if ("cpassword" in fieldValues) {
      temp.cpassword = fieldValues.cpassword ? "" : "This field is required";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((field) => field === "");
    }
  };
  const { values, setValues, inputChangeHandler, errors, setErrors } = useForm(
    { password: "", cpassword: "" },
    true,
    validate
  );

  useEffect(() => {
    if (passwordChangeSuccess) {
      setShowRefDialog({
        isOpen: true,
        title: "Password change successful",
        subTitle: "Proceed to login",
        onConfirm: () => {
          onCloseDialog();
        },
      });
    }
  }, [passwordChangeSuccess]);
  let mismatch = null;
  const changePasswordHandler = (event) => {
    if (values.password !== values.cpassword) {
      mismatch = "Password mismatch!";

      return;
    }
    event.preventDefault();
    if (validate()) {
      mismatch = null;
      onChangePasswordOnFirstTime(values.password, changePasswordId);
    }
  };

  const onCloseDialog = () => {
    setShowRefDialog({
      ...showRefDialog,
      isOpen: false,
    });
    onResetChangePassword();
  };

  return (
    <Grid container>
      <Paper elevation={10} className={classes.paperStyle}>
        <>
          <Grid align="center">
            <Avatar className={classes.avatarStyle}>
              <AccountBoxOutlinedIcon />
            </Avatar>
            <Typography variant="subtitle2">Enter new password</Typography>
          </Grid>

          <Controls.Input
            name="password"
            label="Password"
            value={values.password}
            onChange={inputChangeHandler}
            error={errors.password}
            placeholder="Enter password"
            type="password"
            fullWidth
            required
            className={classes.btnStyle}
          />

          <Controls.Input
            name="cpassword"
            label="Confirm Password"
            value={values.cpassword}
            onChange={inputChangeHandler}
            error={errors.cpassword}
            placeholder="Confirm password"
            type="password"
            fullWidth
            required
            className={classes.btnStyle}
          />
          {mismatch && <span className={classes.error}>{mismatch}</span>}

          {errorMessage && (
            <span className={classes.error}>{errorMessage}</span>
          )}

          {!loading ? (
            <Controls.Button
              onClick={changePasswordHandler}
              className={classes.btnStyle}
              fullWidth
              text="Change Password"
            />
          ) : (
            <Spinner color="primary" />
          )}
        </>
      </Paper>
      <ShowRefNoDialog showRefDialog={showRefDialog} />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
    passwordChangeSuccess: state.auth.passwordOnFirstTimeChanged,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePasswordOnFirstTime: (password, changeId) =>
      dispatch(actionCreators.changePasswordOnFirstLogin(password, changeId)),
    onResetChangePassword: () => dispatch(actionCreators.resetFirstTimeLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeLogin);
