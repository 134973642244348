import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
  suburbList: [],
  clientList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBURB_SUCCESS:
      return updateObject(state, {
        suburbList: action.data,
      });
    case actionTypes.FETCH_CLIENT_SUCCESS:
      return updateObject(state, {
        clientList: action.data,
      });
    default:
      return state;
  }
};

export default reducer;
