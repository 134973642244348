import * as actionTypes from "../actions/action-types";
import { updateObject, getDocketObject } from "../utility";

const initialState = {
  docketData: [],
  total: 0,
  error: false,
  errorMessage: null,
  loading: false,
  loadingMore: false,
  isDeleting: false,
  deleteErrorMessage: null,
  isDeleted: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCKET_RUNS_START:
      return updateObject(state, {
        loading: true,
      });
    case actionTypes.FETCH_MORE_DOCKET_RUNS_START:
      return updateObject(state, {
        loadingMore: true,
      });
    case actionTypes.FETCH_DOCKET_RUNS_SUCCESS:
      return updateObject(state, {
        docketData: action.data,
        total: action.total,
        error: false,
        errorMessage: null,
        loading: false,
      });
    case actionTypes.FETCH_MORE_DOCKET_RUNS_SUCCESS:
      return updateObject(state, {
        docketData: [...state.docketData, ...action.data],
        total: action.total,
        error: false,
        errorMessage: null,
        loadingMore: false,
      });
    case actionTypes.UPDATE_DOCKET_LIST:
      const updatedObject = getDocketObject(action.data, [...state.docketData]);

      const updatedDocketList = [...state.docketData];
      updatedDocketList[updatedObject.docketIndex] = updatedObject.docket;

      return updateObject(state, {
        docketData: updatedDocketList,
      });
    case actionTypes.DELETE_DOCKET_START:
      return updateObject(state, {
        isDeleting: true,
      });
    case actionTypes.DELETE_DOCKET_SUCCESS:
      const reducedDocketList = state.docketData.filter(
        (item) => item.id !== action.docketId
      );

      return updateObject(state, {
        docketData: reducedDocketList,
        isDeleted: true,
        deleteErrorMessage: null,
        isDeleting: false,
        total: state.total - 1,
      });
    case actionTypes.DELETE_DOCKET_FAILED:
      return updateObject(state, {
        deleteErrorMessage: action.message,
      });
    case actionTypes.RESET_DELETED_DOCKET_OPERATION:
      return updateObject(state, {
        isDeleted: false,
      });
    case actionTypes.RESET_DOCKET_LIST:
      return updateObject(state, {
        ...initialState,
      });
    default:
      return state;
  }
};

export default reducer;
