import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
  authToken: null,
  driverData: null,
  error: false,
  errorMessage: null,
  loading: false,
  changePasswordId: null,
  passwordOnFirstTimeChanged: false,
  firstName: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE:
      return updateObject(state, {
        loading: true,
      });
    case actionTypes.IS_FIRST_TIME_LOGIN:
      return updateObject(state, {
        loading: false,
        changePasswordId: action.changePasswordId,
      });
    case actionTypes.SET_AUTH_TOKEN:
      return updateObject(state, {
        authToken: action.authToken,
        driverData: action.driverData,
        error: false,
        errorMessage: null,
        loading: false,
      });
    case actionTypes.SET_FIRST_NAME:
      return updateObject(state, {
        firstName: action.firstName,
      });
    case actionTypes.AUTHENTICATE_ERROR:
      return updateObject(state, {
        error: true,
        errorMessage: action.message,
        loading: false,
      });
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, {
        ...initialState,
      });
    case actionTypes.RESET_IS_FIRST_TIME_LOGIN:
      return updateObject(state, {
        ...initialState,
      });
    case actionTypes.CHANGE_PASSWORD_ON_FIRST_TIME_LOGIN_SUCCESS:
      return updateObject(state, {
        loading: false,
        passwordOnFirstTimeChanged: true,
      });
    default:
      return state;
  }
};

export default reducer;
