import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Controls from "../form/controls/controls";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: theme.spacing(5),
    position: "absolute",
    padding: theme.spacing(2),
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

function ShowRefNoDialog(props) {
  const classes = useStyles();
  const { showRefDialog } = props;
  return (
    <Dialog open={showRefDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <ThumbUpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{showRefDialog.title}</Typography>
        <Typography variant="subtitle2">{showRefDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Controls.Button
          text="Ok"
          color="default"
          onClick={showRefDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
}

export default ShowRefNoDialog;
