import * as actionTypes from "./action-types";

export function authStart() {
  return {
    type: actionTypes.AUTHENTICATE,
  };
}

export function firstTimeLogin(changePasswordId) {
  return {
    type: actionTypes.IS_FIRST_TIME_LOGIN,
    changePasswordId: changePasswordId,
  };
}

export function changePasswordSuccess() {
  return {
    type: actionTypes.CHANGE_PASSWORD_ON_FIRST_TIME_LOGIN_SUCCESS,
  };
}

export function resetFirstTimeLogin() {
  return {
    type: actionTypes.RESET_IS_FIRST_TIME_LOGIN,
  };
}

export function setToken(authToken, driverData) {
  return {
    type: actionTypes.SET_AUTH_TOKEN,
    authToken: authToken,
    driverData: driverData,
  };
}

export function setFirstName(firstName) {
  return {
    type: actionTypes.SET_FIRST_NAME,
    firstName: firstName,
  };
}

export function submitAuthFailed(error) {
  return {
    type: actionTypes.AUTHENTICATE_ERROR,
    message: error,
  };
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationTime");
  localStorage.removeItem("userId");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
}

export function checkAuthTimeout(expirationTime) {
  return function (dispatch) {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
}

export function authenticate(userId, password) {
  return function (dispatch) {
    dispatch(authStart());
    fetch("https://docket.palletman.com.au/development/public/user-login", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify({
        username: userId,
        passwords: password,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.status === "SUCCESSFUL") {
          if (data.user.category === "COMPANY-DRIVER") {
            // All drivers to be login for 8 hours
            const expirationTime = 3600 * 8000;

            const expirationDate = new Date(
              new Date().getTime() + expirationTime
            );
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("expirationTime", expirationDate);
            localStorage.setItem("userId", data.user.username);

            dispatch(setToken(data.access_token, data.user.username));
            dispatch(setFirstName(data.user.firstname));
            dispatch(checkAuthTimeout(expirationTime));
          } else {
            dispatch(submitAuthFailed("Invalid username or password"));
          }
        } else if (data.status === "CHANGE-PASSWORD") {
          dispatch(firstTimeLogin(data.changePasswordId));
        } else if (data.status === "INCORRECT-CREDENTIALS") {
          dispatch(submitAuthFailed("Invalid username or password"));
        } else {
          dispatch(
            submitAuthFailed(
              "There is a connection problem at the moment. Please try again later."
            )
          );
        }
      })
      .catch((err) => {
        dispatch(submitAuthFailed(err.message));
      });
  };
}

export function authCheckState() {
  return function (dispatch) {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationTime = new Date(localStorage.getItem("expirationTime"));
      if (expirationTime > new Date()) {
        const userId = localStorage.getItem("userId");

        dispatch(setToken(token, userId));
        dispatch(
          checkAuthTimeout(expirationTime.getTime() - new Date().getTime())
        );

        fetch(
          `https://docket.palletman.com.au/development/public/get-user/${userId}`
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            dispatch(setFirstName(data.firstname));
          })
          .catch((err) => {});
      } else {
        dispatch(logout());
      }
    }
  };
}

export function changePasswordOnFirstLogin(password, changePasswordId) {
  return function (dispatch) {
    dispatch(authStart());
    fetch(
      "https://docket.palletman.com.au/development/public/change-user-password",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          newpassword: password,
          changePasswordId,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.status === "SUCCESSFUL") {
          dispatch(changePasswordSuccess());
        } else {
          dispatch(submitAuthFailed("Grace time has elapse. Try again!"));
        }
      })
      .catch((err) => {
        dispatch(submitAuthFailed(err.message));
      });
  };
}
