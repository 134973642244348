import * as actionTypes from "./action-types";
import Docket from "../../models/docket";

export function fetchDocketRunsStart() {
  return {
    type: actionTypes.FETCH_DOCKET_RUNS_START,
  };
}

export function fetchMoreDocketRunsStart() {
  return {
    type: actionTypes.FETCH_MORE_DOCKET_RUNS_START,
  };
}

export function deleteDocketStart() {
  return {
    type: actionTypes.DELETE_DOCKET_START,
  };
}

export function fetchDocketRunsSuccess(data, total) {
  return {
    type: actionTypes.FETCH_DOCKET_RUNS_SUCCESS,
    data: data,
    total: total,
  };
}

export function fetchMoreDocketRunsSuccess(data, total) {
  return {
    type: actionTypes.FETCH_MORE_DOCKET_RUNS_SUCCESS,
    data: data,
    total: total,
  };
}

export function deleteDocketSuccess(docketId) {
  return {
    type: actionTypes.DELETE_DOCKET_SUCCESS,
    docketId: docketId,
  };
}

export function fetchDocketRunsFailed(error) {
  return {
    type: actionTypes.FETCH_DOCKET_RUNS_FAILED,
    message: error,
  };
}

export function deleteDocketFailed(error) {
  return {
    type: actionTypes.DELETE_DOCKET_FAILED,
    message: error,
  };
}

export function resetDeletedDocketOperation() {
  return {
    type: actionTypes.RESET_DELETED_DOCKET_OPERATION,
  };
}

export function resetDocketList() {
  return {
    type: actionTypes.RESET_DOCKET_LIST,
  };
}

export function fetchDocketRuns(driverId, offset) {
  return function (dispatch) {
    dispatch(fetchDocketRunsStart());
    fetch(
      "https://docket.palletman.com.au/development/public/get-docket-runs",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          driverid: driverId,
          offset: offset,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        const docketData = [];
        if (data.data.length > 0) {
          data.data.map((item) => {
            docketData.push(processDataArray(item));
          });

          dispatch(fetchDocketRunsSuccess(docketData, data.total));
        } else {
          dispatch(fetchDocketRunsSuccess(docketData, 0));
        }
      })
      .catch((err) => {
        dispatch(fetchDocketRunsFailed(err.message));
      });
  };
}

export function fetchMoreDocketRuns(driverId, offset) {
  return function (dispatch) {
    dispatch(fetchMoreDocketRunsStart());
    fetch(
      "https://docket.palletman.com.au/development/public/get-docket-runs",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          driverid: driverId,
          offset: offset,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.data.length > 0) {
          const docketData = [];

          data.data.map((item) => {
            docketData.push(processDataArray(item));
          });

          dispatch(fetchMoreDocketRunsSuccess(docketData, data.total));
        }
      })
      .catch((err) => {
        dispatch(fetchDocketRunsFailed(err.message));
      });
  };
}

export function deleteDocket(docketId) {
  return function (dispatch) {
    dispatch(deleteDocketStart());
    fetch(
      "https://docket.palletman.com.au/development/public/delete-docket-run",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          docketrunsid: docketId,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
      })
      .then((data) => {
        if (data === "SUCCESSFUL") {
          dispatch(deleteDocketSuccess(docketId));
        } else {
          dispatch(deleteDocketFailed("Operation was not successful"));
        }
      })
      .catch((err) => {
        dispatch(deleteDocketFailed(err.message));
      });
  };
}

export function updateDocketList(data) {
  return {
    type: actionTypes.UPDATE_DOCKET_LIST,
    data: data,
  };
}

function processDataArray(item) {
  const isCompleted = item.iscompleted === "0" ? "no" : "yes";
  const isReturned = item.isreturned === "0" ? "no" : "yes";
  return new Docket(
    item.docketrunsid,
    item.loadno,
    item.dropno,
    item.rego,
    item.suburb,
    item.clientname,
    item.runsenterdate,
    item.runscompleteddate,
    item.deldocket,
    item.salesorder,
    item.pallets,
    item.tonnes,
    item.kilometre,
    item.docketratetypename,
    isCompleted,
    isReturned
  );
}
